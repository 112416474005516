import HttpInterceptorService from '@/services/http-interceptor-service';
import session from './session';

const BACKEND_URL = process.env.VUE_APP_BACKEND_URL;
// const BASIC_AUTH_USERNAME = process.env.VUE_APP_BASIC_AUTH_USERNAME;
// const BASIC_AUTH_PASSWORD = process.env.VUE_APP_BASIC_AUTH_PASSWORD;

export function getAccessToken() {
  return localStorage.getItem(process.env.VUE_APP_ACCESS_STORAGE_KEY);
}

function setAuthHeader(additionalHeaders = {}) {
  return {
    headers: {
      Authorization: `JWT ${getAccessToken()}`,
      ...additionalHeaders,
    },
  };
}

export function setClientsPin(payload) {
  const query = `mutation{setClientsPin(input:{pin:"${
    payload.pin
  }"}){success,errors,access,accessExpiresAt}}`;
  return session.post(BACKEND_URL, { query }, setAuthHeader());
}

/*
* @param {string} orderId - id from active order */
export function getAttachedFiles(orderId = '') {
  const query = `query{
    getAttachedFiles(orderId: "${orderId}") {
      edges{
        node{
          silverId
          orderId
          viewUrl
          downloadUrl
          fileType
          fileToken
        }
      }
    }
  }`;
  return session.post(BACKEND_URL, { query }, setAuthHeader());
}

function getRefresh() {
  if (
    localStorage.getItem(process.env.VUE_APP_REFRESH_STORAGE_KEY) !== undefined
    || localStorage.getItem(process.env.VUE_APP_REFRESH_STORAGE_KEY) !== null
  ) {
    return `"${localStorage.getItem(process.env.VUE_APP_REFRESH_STORAGE_KEY)}"`;
  }
  return null;
}

export function getInfoAboutSettingPinCode(payload) {
  const query = `query{isPinSet(refresh:"${payload.refresh}")}`;
  return session.post(BACKEND_URL, { query });
}

// export async function getMe(payload) {
//   await HttpInterceptorService.makeDelay();
//
//   const query = `query{me{${payload}}}`;
//   return session.post(BACKEND_URL, { query }, setAuthHeader());
// }

export function getReferenceBook(payload = '') {
  const response = '{edges{node{name, silverId, value, id, code}}}';
  const query = `query{referenceBook(names:[${payload}])${response}}`;
  return session.post(BACKEND_URL, { query }, setAuthHeader());
}

export async function getCards() {
  await HttpInterceptorService.makeDelay();

  const keys = 'activatedData silverId cardName cardMask cardType isDefault ifValid bankName';
  const response = `edges{node{${keys}}}`;
  const query = `query{getCards{${response}}}`;
  return session.post(BACKEND_URL, { query }, setAuthHeader());
}

export function getState(productCode) {
  // const query = 'query{getOrderState}';
  const query = `mutation{
    getState(input:{product:"${productCode}"}) {
      success
      errors
      scoring
      state
    }
  }`;
  return session.post(BACKEND_URL, { query }, setAuthHeader());
}

export async function getLastState(clientId) {
  await HttpInterceptorService.makeDelay();

  const query = `mutation{
    getLastState(input:{clientId: "${clientId}"}){
      state
      success
      errors
    }
  }`;
  return session.post(BACKEND_URL, { query }, setAuthHeader());
}

export function getSegmentation() {
  const query = `mutation{
    getSegmentation(input:{product:"cache_online"}){ success errors segmentationData }
  }`;
  return session.post(BACKEND_URL, { query }, setAuthHeader());
}

// export async function getActiveOrder() {
//   await HttpInterceptorService.makeDelay();
//
//   const query = `query{
//       getActiveOrder
//       { edges{ node{ orderId creditValue creditLength product status actionType signature }}}
//     }`;
//   return session.post(BACKEND_URL, { query }, setAuthHeader());
// }

export async function getCredits() {
  await HttpInterceptorService.makeDelay();

  const query = `query{
    getCredits{
      edges{
        node{
          orderId
           creditId
            product
             creditStart
              creditEnd
               creditValue
                monthlyPayment
                 monthlyPaymentDay
                 createdAt
                  remainsToPay
                  nextPaymentDate
                  loanBalance
                  paidPercent
                  paidTotal
                  minMonthlyPayment
                  overdueTotal
                   creditTerm
                    daysBeforePayment
                     isActive
        }
      }
    }
  }`;
  return session.post(BACKEND_URL, { query }, setAuthHeader());
}

// export function getPaymentHistory(creditId) {
//   const query = `query{
//     getPaymentHistory(creditId:"${creditId}") {
//       edges{
//         node{
//           silverId statusCode statusValue paymentSum paymentActions credit { id } card createdAt
//         }
//       }
//     }
//   }`;
//   return session.post(BACKEND_URL, { query }, setAuthHeader());
// }

export function getMinMonthlyPayment(creditId) {
  const query = `mutation{
    getCreditMonthlyPayment(input:{creditId:"${creditId}"}) {
      success
      errors
      monthly
    }
  }`;
  return session.post(BACKEND_URL, { query }, setAuthHeader());
}

export function getProducts() {
  const query = `query{
    getProducts(products:[CACHE_ONLINE, CHECKPLUS, COMMODITY_CREDIT, TRAVEL_MONEY, SOUTH_AG, SERVICE_GUARANTEE, EXTERNAL_LOAN, AG_BASED_ON_SCANS, INSTALLMENT_PLUS, SOUTH_AG_BY_SCANS, AG, INSTALLMENT_REGRESS, BROKER, WORKERS, CASH_CREDIT, MICROCREDIT]){
      edges{
        node{
          code
          name
          silverId
        }
      }
    }
  }`;
  return session.post(BACKEND_URL, { query }, setAuthHeader());
}

export function addClientsCard({ cardNumber, cardName, redirect }) {
  const data = `card: "${cardNumber}" cardName: "${cardName}" redirect: "${redirect}"`;
  const query = `mutation{addClientsCard(input:{${data}})
  {success errors access accessExpiresAt ipayRedirectLink}}`;
  return session.post(BACKEND_URL, { query }, setAuthHeader());
}

/*
    * @param {object} payload - "state", which is located in the file cashForm/index.js
    * payload: {
        bankCard: value,
        personalData: value,
        creditData: value,
        contactPerson: value,
        additionalInfo: value,
        documentData: value,
        address: value,
      }
    * */
export function addOrder(payload = {}) {
  // eslint-disable-next-line no-unused-vars
  const query = `mutation{addOrder(input:{state:${payload}}){success errors}}`;
  // eslint-disable-next-line no-unused-vars
  const query1 = `mutation{
  deleteOrder(input:{delete: true}) {
    success
    errors
  }
}`;
  return session.post(BACKEND_URL, { query }, setAuthHeader());
}

// export function keyAuth(payload) {
//   const query = `mutation{keyAuth(input:{key:"${
//     payload.key
//   }"}){success,errors,access,refresh,accessExpiresAt,isPinSet}}`;
//   return session.post(BACKEND_URL, { query });
// }

export function refreshAccessToken(payload) {
  const query = `mutation{refreshAccessToken(input:{pin: "${payload.pin}"refresh: ${getRefresh()}})
  {success,errors,access,accessExpiresAt}}`;
  return session.post(BACKEND_URL, { query });
}

export function redirectNotifier(orderId) {
  const query = `mutation{
    redirectNotifier(input: {
      orderId: "${orderId}"
    }) {
      success
      errors
    }
  }`;
  return session.post(BACKEND_URL, { query }, setAuthHeader());
}

/*
* @param {object} payload - "state", which is located in the file cashForm/index.js */
export function updateExistingOrder(orderId, state) {
  const query = `mutation{addUpdatedOrder(input:{orderId: "${orderId}", state: ${state}}){success errors}}`;
  return session.post(BACKEND_URL, { query }, setAuthHeader());
}

export function updateClientsPin(oldPin, newPin) {
  const query = `mutation{updateClientsPin(input:{oldPin: "${oldPin}", newPin: "${newPin}"})
  { success errors access accessExpiresAt }}`;
  return session.post(BACKEND_URL, { query }, setAuthHeader());
}

export function creditPayment(creditId = '', clientCard = 0, sum = 0) {
  const fullQuery = `mutation{
    creditPayment(input:{payment:{creditId:"${creditId}", clientCard:${clientCard}, sum:${sum}}}) {
      success
      errors
      access
      accessExpiresAt
      redirectUrl
    }
  }`;
  const partialQuery = `mutation{
    creditPayment(input:{payment:{creditId:"${creditId}", sum:${sum}}}) {
      success
      errors
      access
      accessExpiresAt
      redirectUrl
    }
  }`;
  let query;
  if (clientCard) {
    query = fullQuery;
  } else query = partialQuery;
  return session.post(BACKEND_URL, { query }, setAuthHeader());
}

/*
* @param {array} documentPhotos - [
    {contentType:"application/png", fileBase64:"file"},
    {contentType:"application/jpeg", fileBase64:"file"}
]
* @param {string} orderId - id from active order */
export function verificationPhoto(orderId = '', documentPhotos = []) {
  const query = `mutation{
  identify(input:{orderId:"${orderId}"}) {
    success
    errors
  }
  }`;
  const formData = new FormData();
  documentPhotos.forEach((item, index) => {
    // eslint-disable-next-line no-param-reassign
    delete item.id;
    formData.append(`${index + 1}`, item);
  });
  formData.append('query', query);
  return session.post(BACKEND_URL, formData, setAuthHeader({
    'Content-Type': 'multipart/form-data',
  }));
}

/*
* @param {string} orderId - id from active order */
export function cashConfirmation(orderId) {
  const query = `mutation{
    cashConfirmation(input:{orderId:"${orderId}"}) {
      success
      errors
    }
  }`;
  return session.post(BACKEND_URL, { query }, setAuthHeader());
}

export function clientComplaint(msg, name, email, phone) {
  const query = `mutation{
    clientComplaint(input:{complaint:{text:"${msg}", name:"${name}", email: "${email}", phone:"${phone}"}}) {
      success
      errors
    }
  }`;
  return session.post(BACKEND_URL, { query }, setAuthHeader());
}

export function makeBankCardMain(cardId) {
  const query = `mutation{
    defaultCard(input:{silverId:"${cardId}"}) {
      success
      errors
    }
  }`;
  return session.post(BACKEND_URL, { query }, setAuthHeader());
}

export function newAccessToken() {
  const query = `mutation{
    newAccessToken(input:{}) {
      success
    }
  }`;
  return session.post(BACKEND_URL, { query }, setAuthHeader());
}
export function getCreditReference(creditId) {
  const query = `mutation{getCreditReference(input:{creditId:"${creditId}"}) {
        success
        errors
        token
        isFound
    }
  }`;
  return session.post(BACKEND_URL, { query }, setAuthHeader());
}
// export function checkUserScope() {
//   const query = `mutation{
//     checkUserScope(input:{}) {
//         success
//         errors
//         scopes {
//             scope
//             value
//             key
//         }
//     }
// }`;
//   return session.post(BACKEND_URL, { query }, setAuthHeader());
// }
export function addOrderAttempt() {
  // const product = 'cache_online';
  const query = `mutation{
  addOrderAttempt(input:{}) {
    success
    errors
  }
}`;
  return session.post(BACKEND_URL, { query }, setAuthHeader());
}
export function forgotPassword(payload) {
  const query = `mutation{
    forgotPassword(input: {itn:"${payload.itn}", newPin:"${payload.newPin}"refresh: ${getRefresh()}}) {
        success
        errors
        access
        accessExpiresAt
    }
}`;
  return session.post(BACKEND_URL, { query }, setAuthHeader());
}

// export function getAllMessages() {
//   // HttpInterceptorService.makeDelay();
//   const query = `query{
//   getInformationMessages {
//     edges{
//       node{
//         isRead
//         date
//         messageId
//         textColor
//         textMessage
//         title
//         backgroundColor
//         backgroundImage
//         buttons
//       }
//     }
//   }
// }`;
//   return session.post(BACKEND_URL, { query }, setAuthHeader());
// }
export async function sendInvitationFromQuery(id) {
  await HttpInterceptorService.makeDelay();

  const query = `mutation{
  processorExecution(input:{eventId: "${id}"}) {
    success
    errors
    action
    objectId
  }
}`;
  return session.post(BACKEND_URL, { query }, setAuthHeader());
}
export function updateMessageState() {
  const query = `mutation{
  fetchNewMessages(input:{}) {
    success
    errors
  }
}`;
  return session.post(BACKEND_URL, { query }, setAuthHeader());
}
export function getMessageByParameters(payload) {
  const query = `query{
     getInformationMessages(${payload.payloadType} : ${payload.value}) {
    edges{
      node{
        isRead
        date
        messageId
        textMessage
        textColor
        title
        backgroundColor
        backgroundImage
        buttons
      }
    }
  }
}`;
  return session.post(BACKEND_URL, { query }, setAuthHeader());
}
export function sandClientsReflection(payload) {
  const query = `mutation{
  pushReflectionMessage(input:{messageId:"${payload.messageId}",buttonHandler:"${payload.buttonHandler}", reflection:"${payload.reflection}"}) {
    success
    errors
    access
    accessExpiresAt
  }
}`;
  return session.post(BACKEND_URL, { query }, setAuthHeader());
}
